<template>
  <BaseModal ref='modal' title="Dossier kan gefactureerd worden">
    <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
      <dt>Dossier</dt>
      <dd>{{ propertyDisplay }}</dd>
      <dt v-if="modalData.hasAdvance">Voorschot</dt>
      <dd v-if="modalData.hasAdvance">
        <span v-if="modalData.advanceReady">Voldaan</span>
        <span v-else>Niet voldaan</span>
      </dd>
      <dt v-if="modalData.hasSuspensiveCondition">Opschortende VW</dt>
      <dd v-if="modalData.hasSuspensiveCondition">
        <span v-if="modalData.suspensiveConditionsReady">Voldaan</span>
        <span v-else>Niet voldaan</span>
      </dd>
    </dl>
    <div class="tw-flex  tw-flex-row-reverse">
      <router-link
        :to="{ name: 'PropertyInvoice', params: { id: modalData.propertyId } }"
        target="_blank"
        class="btn btn-md btn-success"
        @click.native="hide"
      >
        Eindfactuur opmaken
      </router-link>
    </div>
  </BaseModal>
</template>

<script>
import { successModal } from '@/modalMessages'
import { updateAdvance } from '@/services/transactions'
import { getProperty } from '@/services/properties'

export default {
  name: 'StartFinalInvoiceModal',
  data () {
    return {
      propertyDisplay: '',
      modalData: {}
    }
  },
  methods: {
    async show (modalData) {
      this.modalData = modalData
      const propertyResponse = await getProperty(this.modalData.propertyId)
      const prop = propertyResponse.data
      const address = prop.box ? `${prop.street} ${prop.number} ${prop.box}` : `${prop.street} ${prop.number}`
      this.propertyDisplay = `${address}, ${prop.city.zip_code} ${prop.city.name}`
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    validateAmountGtZero ({ getFormValues }) {
      const value = getFormValues().amount
      return parseFloat(value) >= 0
    },
    validateAmountLtBalance ({ getFormValues }) {
      const value = getFormValues().amount
      return parseFloat(value) <= parseFloat(this.advance.balance)
    },
    async submit (data) {
      try {
        const response = await updateAdvance(this.advance.id, data)
        this.hide()
        successModal('De betaling werd geregistreerd.')
        this.$emit('paid')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'payAdvanceForm')
      }
    }
  }
}
</script>
